.clearfix:before,
.clearfix:after {
  content: ".";
  display: block;
  height: 0;
  overflow: hidden;
}
.clearfix:after {
  clear: both;
}

.overlay {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: black;
  opacity: 0;
  overflow: hidden;
  width: 100%;
  height: 100%;
  visibility: hidden;
}
.slider {
  width: 100%;
  margin: 0 auto;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  transition: 750ms all ease-in-out;
  flex-shrink: 0;
  overflow: hidden;
}
.sliderImg {
  width: 100%;
  overflow: hidden;
  height: 500px;
}
@media (max-width: 700px) {
  .sliderImg {
    height: 500px;
  }
}
.sliderImg img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: 500ms all ease-in-out; /* The image used */ /* Used if the image is unavailable */ /* You must set a specified height */
}
.sliderImg img:hover {
}
.sliderText {
  width: 100%;
  color: black;
}
/* .sliderText h1 {
  margin: 0px;
  font-size: 50px;
} */
.sliderAction {
  background: #5fb6c3;
  border-radius: 10px;
}

.mainContainer {
  width: 100%;
  margin: 0 auto;
  display: flex;
  flex-wrap: nowrap;
  position: relative;
  overflow-x: hidden;
  overflow-y: hidden;
  z-index: 1;
  box-sizing: border-box;
}
.indicatorWrapper {
  display: flex;
  flex-wrap: nowrap;
  z-index: 2;
}

.dots {
  width: 10px;
  height: 10px;
  background-color: black;
  margin: 0px 10px;
  transition: 750ms all ease-in-out;
  border-radius: 20px;
}
.dots:hover {
  cursor: pointer;
}
.navigatorLeft {
  position: absolute;
  color: white;
  top: 45%;
  left: 15px;
  z-index: 2;
  cursor: pointer;
}
.navigatorRight {
  position: absolute;
  color: white;
  top: 45%;
  right: 15px;
  z-index: 2;
  cursor: pointer;
}
@media (max-width: 700px) {
  .small_button {
    font-size: 8px;
    padding: 5px 10px;
  }
  .imageForMobile{
    width:250px;
    height:300px;
    border-radius: 7px 7px 0px 0px;
  }
  .specialImagesForMobile{
    width:60px;
    height:60px;
  }
  .treatmentItems {
    width: 100% !important;
  }
}
.map-container {
  height: 100%;
  width: 100%;
}
/*===========================================ENTER TRANSITION ==================================*/

/*=== First animation ===*/
.myanimate-enter {
  opacity: 0;
  top: 40px;
}
.myanimate-enter-active {
  opacity: 1;
  top: 0px;
  transition: all 1000ms;
}
.myanimate-enter-done {
  top: 0px;
  opacity: 1;
}
/*=== First animation ===*/
/*=== Second animation ===*/
.myanimateImage-enter {
  opacity: 0;
  top: 15px;
}
.myanimateImage-enter-active {
  opacity: 1;
  top: -60px !important;
  transition: all 1000ms;
}
.myanimateImage-enter-done {
  top: -60px !important;
  opacity: 1;
}
/*=== Second animation ===*/
/*=== Third animation ===*/
.myanimateText-enter {
  opacity: 0;
  top: 100px;
}
.myanimateText-enter-active {
  opacity: 1 !important;
  top: 0px !important;
  transition: all 400ms;
}
.myanimateText-enter-done {
  top: 0px !important;
  opacity: 1 !important;
}
.myanimateText1-enter {
  opacity: 0;
  top: 100px;
}
.myanimateText1-enter-active {
  opacity: 1 !important;
  top: 0px !important;
  transition: all 800ms;
}
.myanimateText1-enter-done {
  top: 0px !important;
  opacity: 1 !important;
}
.myanimateText2-enter {
  opacity: 0;
  top: 100px;
}
.myanimateText2-enter-active {
  opacity: 1 !important;
  top: 0px !important;
  transition: all 1200ms;
}
.myanimateText2-enter-done {
  top: 0px !important;
  opacity: 1 !important;
}
.myanimateText3-enter {
  opacity: 0;
  top: 100px;
}
.myanimateText3-enter-active {
  opacity: 1 !important;
  top: 0px !important;
  transition: all 1600ms;
}
.myanimateText3-enter-done {
  top: 0px !important;
  opacity: 1 !important;
}
/*=== Third animation ===*/
/*=== Fourth animation ===*/
.myanimateTreatments-enter {
  opacity: 0;
  top: 100px;
}
.myanimateTreatments-enter-active {
  opacity: 1 !important;
  top: 0px !important;
  transition: all 3000ms;
}
.myanimateTreatments-enter-done {
  top: 0px !important;
  opacity: 1 !important;
}
/*=== Fourth animation ===*/

/*============================EXIT TRANSITION ==========================================*/
.myanimate-exit {
  opacity: 1;
  top: 0px;
}
.myanimate-exit-active {
  opacity: 0;
  top: 40px;
  transition: all 1000ms;
}
.myanimate-exit-done {
  opacity: 0;
  top: 40px;
}
/*=== Second animation ===*/
.myanimateImage-exit {
  opacity: 0;
  top: -60px;
}
.myanimateImage-exit-active {
  opacity: 1;
  top: 15px !important;
  transition: all 1000ms;
}
.myanimateImage-exit-done {
  top: 15px !important;
  opacity: 1;
}
/*=== Second animation ===*/

.treatmentItems {
  display: flex;
  width: 80%;
  margin: 0 auto;
  flex-wrap: wrap;
  justify-content: center;
}

/*===Testing===*/
.item-enter {
  opacity: 0;
}
.item-enter-active {
  opacity: 1;
  transition: all 5000ms !important;
}
.item-enter-done {
  opacity: 1;
}
.item-exit {
  opacity: 1;
}
.item-exit-active {
  opacity: 0;
  transition: all 5000ms ;
}


.bannerImagesMobile{
  object-fit: cover;
  width:360px;
  height: 450px;
}

@media (max-width: 900px) {
  .bannerImagesMobile{
    
    display: none;
  }
  .overlay{
    border-radius: 50%;
  }
}

.embedelement{
  overflow: hidden;
}

.embedelement::-webkit-scrollbar {
  display: none;
}

.progress-bar {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 5px;
  width: 100%;
  z-index: 11111;
  background:radial-gradient(circle, rgba(34,193,195,1) 0%, rgba(253,187,45,1) 100%);
  transform-origin: 0%;
}
